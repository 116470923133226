:root {
  --game-popup-btn-close-bg-color: #56679e;
}

.game-popup {
  position: absolute;
  z-index: 100;
  @include calculatePopupSizeVH(371, 176);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3vh;
  background-color: var(--game-bar-bg-color);
  pointer-events: auto;
  animation:
    popup-open 0.2s linear 1,
    popup-close 0.21s 4.8s linear 1;

  @keyframes popup-open {
    0% {
      transform: scale(0.8);
    }

    100% {
      transform: scale(1);
    }
  }

  @keyframes popup-close {
    0% {
      transform: scale(1);
    }

    100% {
      opacity: 0;
      transform: scale(0);
    }
  }

  &::before {
    content: "";
    position: absolute;
    @include calculateItemSizeVH(20, 20);
    background-color: inherit;
    transform: rotate(45deg);
  }

  &-close {
    position: absolute;
    right: 1.5vh;
    top: 1.5vh;
    padding: 0;
    border: none;
    @include resetOutline;
    border-radius: 50%;
    @include calculateItemSizeVH(20, 20);
    background-color: var(--game-popup-btn-close-bg-color);
    transform: rotate(45deg);
    transition: transform 0.3s ease-in-out;
    cursor: pointer;

    &::before {
      content: "";
      position: absolute;
      left: 10%;
      top: 46%;
      width: 80%;
      height: 8%;
      border-radius: 1px;
      background-color: #fff;
    }

    &::after {
      content: "";
      position: absolute;
      left: 10%;
      top: 46%;
      width: 80%;
      height: 8%;
      border-radius: 1px;
      background-color: #fff;
      transform: rotate(90deg);
    }

    &:hover,
    &:focus {
      transform: rotate(135deg);
    }
  }

  &-descr {
    width: 82%;
    padding: 1.5vh 0;
    @include fontProperty(1.8vh, 130%, 400, #fff, left);
    font-style: italic;
  }
}

// for popup tail
.top-right.game-popup::before {
  left: 10%;
  bottom: -5%;
}

.bottom-right.game-popup::before {
  left: 10%;
  top: -5%;
}

.top-left.game-popup::before {
  right: 10%;
  bottom: -5%;
}

.bottom-left.game-popup::before {
  right: 10%;
  top: -5%;
}

@media (max-aspect-ratio: 7/4) {
  .game-popup {
    @include calculatePopupSizeVW(371, 176);

    &-close {
      @include calculateItemSizeVW(20, 20);
    }

    &-descr {
      font-size: 1.1vw;
    }
  }
}
