:root {
  --btn-sound-bg-color: #2a396b;

  --game-bar-bg-color: #111b3d;
  --game-bar-section-bg-color: #2a396b;
  --clock-rank-color: #7bbceb;
}

.page-game {
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;

  &-logo {
    position: absolute;
    top: 3.7vh;
    left: 0.5vw;
    z-index: 100;
  }

  &-container {
    position: relative;
    z-index: 100;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 96%;
    max-width: 180vh;
    min-height: inherit;
    margin: 0 auto;
    pointer-events: none;
  }
}

.game {
  position: absolute;
  left: 0;
  top: 0;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;

  &-container {
    position: relative;
    margin: 0 auto;
    width: 100%;
    max-width: 200vh;
    height: 100%;
  }

  &-controll {
    display: none;
    min-height: inherit;
  }

  &-controll.active {
    display: block;
  }

  &-exit {
    position: absolute;
    top: 4.3vh;
    right: 0.6vw;
    z-index: 3;
    @include calculateItemSizeVH(60, 60);
    padding: 0;
    border: none;
    @include resetOutline;
    border-radius: 50%;
    background-color: var(--btn-sound-bg-color);
    pointer-events: auto;
    cursor: pointer;
    transition: transform 0.3s ease-in-out;

    &::before {
      content: "";
      position: absolute;
      left: 25%;
      top: 47%;
      width: 50%;
      height: 6%;
      background-color: #fff;
      transform: rotate(-45deg);
    }

    &::after {
      content: "";
      position: absolute;
      left: 25%;
      top: 47%;
      width: 50%;
      height: 6%;
      background-color: #fff;
      transform: rotate(45deg);
    }

    &:focus {
      transform: rotate(90deg);
    }

    &:hover {
      transform: rotate(-90deg);
    }
  }

  &-exit-dark {
    background-color: var(--game-bar-bg-color);
  }
}

.game.active {
  display: block;
}

.btn-sound {
  position: absolute;
  top: 4.3vh;
  right: 4.6vw;
  z-index: 100;
  @include calculateItemSizeVH(60, 60);
  padding: 0;
  border: none;
  @include resetOutline;
  border-radius: 50%;
  background-color: var(--btn-sound-bg-color);
  transition: transform 0.3s ease-in-out;
  pointer-events: auto;
  cursor: pointer;

  &:hover {
    transform: scale(1.1);
  }

  &:focus {
    transform: scale(1.05);
  }

  &-ico {
    position: absolute;
    left: 33%;
    top: 23%;
    @include calculateItemSizeVH(20, 30);
  }

  &::after {
    content: "";
    position: absolute;
    top: 22%;
    left: 26%;
    @include calculateItemSizeVH(40, 2);
    background-color: #fff;
    transform-origin: left;
    transform: rotate(45deg);
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }
}

.off.btn-sound::after {
  opacity: 1;
}

.game-bar {
  display: grid;
  grid-template-columns: 1fr 3.7fr 1fr;
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  margin-bottom: 3vh;
  width: 100%;
  height: 15vh;
  max-height: 12vw;
  border-radius: 4vh;
  background-color: var(--game-bar-bg-color);

  &-left {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 3vh;
    border-radius: inherit;
    background-color: var(--game-bar-section-bg-color);
    @include fontProperty(3.3vh, 130%);
  }

  &-mid {
    padding: 0 2vw;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  &-targets {
    flex-grow: 1;
    display: none;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
  }

  &-targets.show {
    display: flex;
  }

  &-target {
    @include calculateItemSizeVH(154, 120);
    filter: grayscale(1) opacity(0.3) drop-shadow(0 0 0 var(--clock-rank-color));
    transition: filter 0.5s ease-in-out;
  }

  &-target.show {
    filter: grayscale(0) opacity(1);
  }

  &-right {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1.3vh 1vh;
    border-radius: inherit;
    background-color: var(--game-bar-section-bg-color);
  }

  &-footnote {
    padding: 1.2vh 2.5vw;
    @include fontProperty(1.32vh, 130%, 300, var(--game-bar-bg-color), right);
  }
}

.clock {
  flex-grow: 1;
  display: grid;
  grid-template-columns: 3fr 1fr 3fr;
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  width: 80%;

  &-header {
    margin-bottom: 0.7vh;
  }

  &-descr {
    @include fontProperty(2vh, 130%, 300, #fff);
  }

  &-col {
    display: flex;
    flex-direction: column;
  }

  &-ranks {
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    margin-bottom: 0.7vh;
  }

  &-rank {
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.5vw;
    background-color: var(--game-bar-bg-color);
    @include fontProperty(4vh, 100%, 700, var(--clock-rank-color));
  }

  &-rank:nth-child(2n - 1) {
    margin-right: 0.2vw;
  }

  &-colon {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 3vh;
    @include fontProperty(3vh, 100%, 700, var(--clock-rank-color));
  }
}

@media (max-aspect-ratio: 7/4) {
  .game-bar {
    &-left {
      font-size: 1.87vw;
    }

    &-target {
      @include calculateItemSizeVW(154, 120);
    }

    &-footnote {
      font-size: 1vw;
    }
  }

  .clock {
    &-descr {
      font-size: 1.3vw;
    }

    &-rank {
      font-size: 3vw;
    }
  }

  .btn-sound {
    right: 7vh;
  }
}
