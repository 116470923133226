:root {
  --leaderboard-bg-color: #2a396b;

  --leaderboard-pick-btn-bg-color: #1e2a4f;
  --leaderboard-main-violet-color: #eb018d;
}

.leaderboard {
  position: relative;
  display: none;
  width: 100%;
  min-height: inherit;
  background-color: var(--leaderboard-bg-color);
  overflow: hidden;

  &-container {
    justify-content: center;
  }

  &-inner {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @include calculateItemSizeVH(1920, 1080);
    width: 100%;
    padding: 4vh 3vh;
    pointer-events: auto;
  }

  &-exit {
    position: absolute;
    top: 3vh;
    left: 3vh;
    z-index: 3;
    @include calculateItemSizeVH(66, 66);
    padding: 0;
    border: 0.4vh solid #fff;
    @include resetOutline;
    border-radius: 50%;
    background-color: var(--leaderboard-bg-color);
    pointer-events: auto;
    cursor: pointer;
    transition: transform 0.3s ease-in-out;

    &::before {
      content: "";
      position: absolute;
      left: 25%;
      top: 47%;
      width: 50%;
      height: 6%;
      background-color: #fff;
      transform: rotate(-45deg);
    }

    &::after {
      content: "";
      position: absolute;
      left: 25%;
      top: 47%;
      width: 50%;
      height: 6%;
      background-color: #fff;
      transform: rotate(45deg);
    }

    &:focus {
      transform: rotate(90deg);
    }

    &:hover {
      transform: rotate(-90deg);
    }
  }

  &-bg-left {
    position: absolute;
    bottom: -71.74%;
    left: -93%;
    z-index: 2;
    @include calculateItemSizeVH(1990, 1360);
    max-width: initial;
    animation: left-hand-move 4s ease-in-out infinite alternate;
  }

  @keyframes left-hand-move {
    0% {
      transform: translate(0);
    }

    100% {
      transform: translate(-10%, 10%);
    }
  }

  &-bg-right {
    position: absolute;
    top: -31.2%;
    right: -161%;
    z-index: 2;
    @include calculateItemSizeVH(3312, 1350);
    max-width: initial;
    animation: right-hand-move 4s ease-in-out infinite alternate;
  }

  @keyframes right-hand-move {
    0% {
      transform: translate(0);
    }

    100% {
      transform: translate(10%, -10%);
    }
  }

  &-bg-item-1 {
    position: absolute;
    left: -13%;
    top: -8%;
    z-index: 1;
    @include calculateItemSizeVH(595, 698);
    max-width: initial;
    animation: bg-item-1-swing 4s ease-in-out infinite alternate;
  }

  @keyframes bg-item-1-swing {
    0% {
      transform: rotate(-5deg);
    }

    100% {
      transform: rotate(5deg);
    }
  }

  &-bg-item-2 {
    position: absolute;
    right: 2%;
    bottom: -43%;
    z-index: 1;
    @include calculateItemSizeVH(595, 698);
    max-width: initial;
    transform: rotate(99deg);
    animation: bg-item-2-swing 4s ease-in-out infinite alternate;
  }

  @keyframes bg-item-2-swing {
    0% {
      transform: rotate(94deg);
    }

    100% {
      transform: rotate(104deg);
    }
  }

  &-bg-item-3 {
    position: absolute;
    left: 22.5%;
    top: -12%;
    z-index: 1;
    @include calculateItemSizeVH(284, 260);
    border-radius: 50%;
    max-width: initial;
    animation: bg-item-rotate-non-stop 120s linear infinite;
  }

  @keyframes bg-item-rotate-non-stop {
    0% {
      transform: rotate(0);
    }

    100% {
      transform: rotate(-360deg);
    }
  }

  &-bg-item-4 {
    position: absolute;
    right: -11%;
    bottom: 40%;
    z-index: 1;
    @include calculateItemSizeVH(284, 260);
    border-radius: 50%;
    max-width: initial;
    animation: bg-item-rotate-non-stop 140s linear infinite reverse;
  }

  &-bg-item-5 {
    position: absolute;
    right: 37.4%;
    bottom: -14.7%;
    z-index: 1;
    @include calculateItemSizeVH(284, 260);
    border-radius: 50%;
    max-width: initial;
    animation: bg-item-rotate-non-stop 100s linear infinite;
  }

  &-header {
    z-index: 1;
    margin-bottom: 3vh;
    @include fontProperty(4.65vh, 150%, 700);
  }

  &-pick-btns {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 2.4vh;
    margin-bottom: 5vh;
  }

  &-pick-btn {
    position: relative;
    justify-self: center;
    @include calculateItemSizeVH(262, 143);
    padding: 0;
    border: none;
    @include resetOutline;
    border-radius: 3vh;
    background-color: var(--leaderboard-pick-btn-bg-color);
    overflow: hidden;
    pointer-events: auto;
    cursor: pointer;
    transition: transform 0.3s ease-in-out;

    &:focus {
      transform: translateY(-2%);
    }

    &:hover {
      transform: translateY(-4%);
    }
  }

  &-pick-btn-header {
    position: absolute;
    top: 24%;
    left: 10%;
    z-index: 1;
    fill: #fff;
    transition: fill 0.2s ease-in-out;
  }

  &-pick-btn.active &-pick-btn-header {
    fill: var(--leaderboard-main-violet-color);
  }

  &-pick-btn-header-1 {
    @include calculateItemSizeVH(200, 59);
  }

  &-pick-btn-header-2 {
    @include calculateItemSizeVH(125, 53);
  }

  &-pick-btn-header-3 {
    @include calculateItemSizeVH(136, 53);
  }

  &-pick-btn-header-4 {
    @include calculateItemSizeVH(155, 60);
  }

  &-controll-btns {
    display: flex;
    justify-content: center;
    gap: 2vh;
  }

  &-controll-btn {
    position: relative;
    justify-self: center;
    display: flex;
    justify-content: center;
    align-items: center;
    @include calculateItemSizeVH(300, 66);
    padding: 0;
    border: 0.3vh solid #fff;
    @include resetOutline;
    border-radius: 3vh;
    @include fontProperty(1.8vh, 150%);
    background-color: transparent;
    cursor: pointer;
    transition:
      transform 0.3s ease-in-out,
      border-color 0.3s ease-in-out,
      color 0.3s ease-in-out;

    &:focus {
      transform: scale(1.02);
    }

    &:hover {
      transform: scale(1.05);
    }

    &:disabled {
      border-color: var(--leaderboard-pick-btn-bg-color);
      color: var(--leaderboard-pick-btn-bg-color);
    }
  }

  &-footnote {
    z-index: 2;
  }
}

.leaderboard.active {
  display: block;
}

.table {
  display: grid;
  grid-template-columns: 1fr 5fr 5fr;
  gap: 1vh;

  &-wrapper {
    z-index: 2;
    max-width: 65%;
  }

  &-head {
    margin-bottom: 1vh;
  }

  &-header {
    @include fontProperty(2vh, 150%, 700);
  }

  &-main-wrapper {
    height: 20vh;

    &::before {
      content: "Loading..";
      position: absolute;
      left: 0;
      top: 0;
      z-index: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      @include fontProperty(3vh, 150%, 700);
      background-color: var(--leaderboard-bg-color);
      opacity: 0;
      transition: opacity 0.3s ease-in-out;
      pointer-events: none;
    }

    &::after {
      --size: 6vh;
      content: "";
      position: absolute;
      right: 30%;
      top: 7vh;
      z-index: 2;
      width: var(--size);
      height: var(--size);
      border-left: 0.6vh solid var(--leaderboard-main-violet-color);
      border-right: 0.6vh solid var(--leaderboard-main-violet-color);
      border-radius: 50%;
      opacity: 0;
      transition: opacity 0.3s ease-in-out;
      pointer-events: none;

      animation: loader-rotate 2s linear infinite;

      @keyframes loader-rotate {
        0% {
          transform: rotate(0);
        }

        100% {
          transform: rotate(360deg);
        }
      }
    }
  }

  &-main-wrapper.loading {
    &::before {
      opacity: 1;
    }

    &::after {
      opacity: 1;
    }
  }

  &-main-wrapper.empty {
    &::before {
      content: "Тут пока никого нет :(";
      opacity: 1;
    }
  }

  &-cell {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 6vh;
    border-radius: 2vh;
    @include fontProperty(1.8vh, 150%);
    background-color: var(--leaderboard-pick-btn-bg-color);
  }

  &-foot {
    margin-bottom: 5vh;
    padding-top: 1vh;
  }

  &-foot-cell {
    background-color: var(--leaderboard-main-violet-color);
  }
}

@media (max-aspect-ratio: 7/4) {
  .leaderboard {
    &-bg-left {
      @include calculateItemSizeVW(1990, 1360);
    }

    &-bg-right {
      @include calculateItemSizeVW(3312, 1350);
    }

    &-bg-item-1,
    &-bg-item-2 {
      @include calculateItemSizeVW(595, 698);
    }

    &-bg-item-3,
    &-bg-item-4,
    &-bg-item-5 {
      @include calculateItemSizeVW(284, 260);
    }

    &-logo {
      @include calculateItemSizeVW(186, 64);
    }

    &-header {
      font-size: 2.5vw;
    }

    &-pick-btn {
      @include calculateItemSizeVW(262, 143);
      border-radius: 2vw;
    }

    &-pick-btn-header-1 {
      @include calculateItemSizeVW(200, 59);
    }

    &-pick-btn-header-2 {
      @include calculateItemSizeVW(125, 53);
    }

    &-pick-btn-header-3 {
      @include calculateItemSizeVW(136, 53);
    }

    &-pick-btn-header-4 {
      @include calculateItemSizeVW(155, 60);
    }
  }

  .table {
    &-wrapper {
      max-width: 85%;
    }
  }
}

@media (orientation: portrait) {
  .leaderboard {
    &-inner {
      justify-content: center;
      @include calculateItemSizeVW(500, 890, 500);
      width: 100%;
      padding: 4vw 2vw;
    }

    &-exit {
      top: 6vw;
      left: 3vw;
      @include calculateItemSizeVW(66, 66, 500);
    }

    &-bg-left {
      bottom: -25.74%;
      @include calculateItemSizeVW(1990, 1360, 2000);
    }

    &-bg-right {
      top: -20.2%;
      right: -155%;
      @include calculateItemSizeVW(3312, 1350, 2000);
    }

    &-bg-item-1 {
      left: -13%;
      top: -8%;
      @include calculateItemSizeVW(595, 698, 1500);
    }

    &-bg-item-2 {
      right: -15%;
      bottom: -11%;
      transform: rotate(0);
      @include calculateItemSizeVW(595, 698, 1500);
      animation: bg-item-1-swing 4s ease-in-out infinite alternate;
    }

    &-bg-item-3 {
      left: initial;
      right: 0;
      top: -6%;
      @include calculateItemSizeVW(284, 260, 1500);
    }

    &-bg-item-4 {
      right: initial;
      left: 6%;
      bottom: -6%;
      @include calculateItemSizeVW(284, 260, 1500);
    }

    &-bg-item-5 {
      display: none;
    }

    &-logo {
      @include calculateItemSizeVW(125, 43, 500);
      margin-bottom: 4vw;
    }

    &-header {
      margin-bottom: 4vw;
      font-size: 8vw;
      line-height: 120%;
    }

    &-pick-btns {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 2vw;
      margin-bottom: 5vw;
    }

    &-pick-btn {
      @include calculateItemSizeVW(220, 90, 500);
    }

    &-pick-btn-header-1 {
      @include calculateItemSizeVW(200, 59, 900);
    }

    &-pick-btn-header-2 {
      @include calculateItemSizeVW(125, 53, 900);
    }

    &-pick-btn-header-3 {
      @include calculateItemSizeVW(136, 53, 900);
    }

    &-pick-btn-header-4 {
      @include calculateItemSizeVW(155, 60, 900);
    }

    &-controll-btns {
      gap: 5vw;
    }

    &-controll-btn {
      @include calculateItemSizeVW(150, 66, 500);
      border-radius: 8vw;
      font-size: 3vw;

      &::after {
        --size: 5vw;
        content: "";
        position: absolute;
        top: calc(50% - var(--size) / 2);
        width: var(--size);
        height: var(--size);
        border-left: 1vw solid #fff;
        border-top: 1vw solid #fff;
      }

      span {
        display: none;
      }
    }

    &-controll-btn-left {
      &::after {
        left: calc(54% - var(--size) / 2);
        transform: rotate(-45deg);
      }
    }

    &-controll-btn-right {
      &::after {
        right: calc(54% - var(--size) / 2);
        transform: rotate(135deg);
      }
    }

    &-footnote {
      display: none;
    }
  }

  .table {
    display: grid;
    grid-template-columns: 13vw 1fr 1fr;
    gap: 1vw;

    &-wrapper {
      max-width: 100%;
    }

    &-head {
      margin-bottom: 1vw;
    }

    &-header {
      font-size: 4vw;
    }

    &-main-wrapper {
      height: 32vw;

      &::before {
        font-size: 4vw;
      }

      &::after {
        --size: 6vw;
        top: 13vw;
        border-width: 0.7vw;
      }
    }

    &-cell {
      height: 10vw;
      border-radius: 2vw;
      font-size: 3vw;
    }

    &-foot {
      margin-bottom: 5vw;
      padding-top: 1vw;
    }
  }
}
