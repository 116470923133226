:root {
}

.popup {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  @include calculatePopupSizeVH(690, 480);
  padding: 5vh 0;
  border-radius: 3vh;
  background-color: #fff;
  animation: modal-show 0.5s ease 1;

  @keyframes modal-show {
    0% {
      transform: scale(0.6);
    }

    70% {
      transform: scale(1.1);
    }

    100% {
      transform: scale(1);
    }
  }

  &-wrapper {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 1000;
    // display: flex;
    display: none;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    background-color: #000000aa;
  }

  &-wrapper.active {
    display: flex;
  }

  &-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 75%;
  }

  &-header {
    margin-bottom: 4vh;
    @include fontProperty(4.8vh, 120%, 700, #000);
  }

  &-descr {
    margin-bottom: 4vh;
    @include fontProperty(2.5vh, 120%, 400, #000);
  }

  &-descr-blue {
    color: #21aaad;
  }

  &-btn {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    @include calculatePopupSizeVH(450, 96);
    width: 100%;
    padding: 0;
    border: none;
    border-radius: 5vh;
    @include resetOutline;
    @include fontProperty(2.8vh, 120%, 700);
    text-decoration: none;
    background: linear-gradient(90deg, #009fdf 0%, #62bd48 100%);
    background-size: 150% 150%;
    animation: gradient 3s linear infinite alternate;
    transition: transform 0.3s ease-in-out;
    cursor: pointer;

    &:hover {
      transform: scale(1.07);
    }

    @keyframes gradient {
      0% {
        background-position: 0% 50%;
      }
      50% {
        background-position: 100% 50%;
      }
      100% {
        background-position: 0% 50%;
      }
    }
  }

  &-btn-transparent {
    margin-bottom: 2vh;
  }

  &-btn-inner {
    --pseodu-border-size: 0.4vh;
    position: absolute;
    top: var(--pseodu-border-size);
    left: var(--pseodu-border-size);
    display: flex;
    justify-content: center;
    align-items: center;
    width: calc(100% - var(--pseodu-border-size) * 2);
    height: calc(100% - var(--pseodu-border-size) * 2);
    border-radius: inherit;
    background-color: #fff;
    color: #21aaad;
  }

}

.emoji {
  display: inline-block;
  vertical-align: middle;
  height: 2.4vmax;
}

@media (max-aspect-ratio: 7/4) {
  .popup {
    @include calculatePopupSizeVW(690, 480);

    &-header {
      margin-bottom: 2vh;
      font-size: 2.6vw;
    }

    &-descr {
      margin-bottom: 2vh;
      font-size: 1.5vw;
    }

    &-btn {
      @include calculatePopupSizeVW(450, 96);
      font-size: 1.7vw;
    }

    &-btn-transparent {
      margin-bottom: 1vh;
    }

    &-btn-inner {
      --pseodu-border-size: 0.2vh;
    }
  }
}
