:root {
  --game-1-bg-color: #c8dfff;
}

.game-1 {
  background-color: var(--game-1-bg-color);

  &-main-group {
    position: absolute;
    bottom: 0%;
    left: 0%;
    z-index: 1;
    @include calculateItemSizeVH(1920, 1080);
    pointer-events: none;
  }

  &-main-group-back {
    position: absolute;
    bottom: -32.74%;
    left: -112.8%;
    z-index: 1;
    @include calculateItemSizeVH(5700, 2345);
    max-width: initial;
  }

  &-car {
    position: absolute;
    bottom: 49%;
    right: 29%;
    z-index: 1;
    @include calculateItemSizeVH(225, 137);
    animation: ambulance-drive 15s linear infinite;
  }

  @keyframes ambulance-drive {
    0% {
      transform: translateX(800%);
    }

    30% {
      transform: translateX(0%);
    }

    59% {
      transform: translateX(0%);
    }

    60% {
      transform: translateX(0%) matrix(-1, 0, 0, 1, 1, 0);
    }

    100% {
      transform: translateX(800%) matrix(-1, 0, 0, 1, 1, 0);
    }
  }

  &-person-6 {
    position: absolute;
    bottom: 52%;
    right: 38.2%;
    z-index: 1;
    @include calculateItemSizeVH(101, 89);
    animation: game-1-person-6 15s linear infinite;
  }

  @keyframes game-1-person-6 {
    0% {
      opacity: 0;
    }

    34% {
      opacity: 0;
      transform: scale(0.8);
    }

    35% {
      opacity: 1;
      transform: scale(1);
    }

    56% {
      opacity: 1;
      transform: scale(1);
    }

    58% {
      opacity: 0;
      transform: scale(0.8);
    }

    100% {
      opacity: 0;
    }
  }

  &-trees {
    position: absolute;
    bottom: 41%;
    right: -73.8%;
    z-index: 1;
    @include calculateItemSizeVH(1414, 378);
  }

  &-person-4 {
    position: absolute;
    bottom: 43.3%;
    right: 17.2%;
    z-index: 1;
    @include calculateItemSizeVH(232, 277);
  }

  &-person-3 {
    position: absolute;
    bottom: 38%;
    left: -38.8%;
    z-index: 1;
    @include calculateItemSizeVH(63, 111);
    animation: game-1-person-3 7s linear infinite;
  }

  @keyframes game-1-person-3 {
    0% {
      transform: translate(0, 0);
    }

    30% {
      transform: translate(526%, 0);
    }

    99% {
      opacity: 1;
    }

    100% {
      transform: translate(1374%, -87%);
      opacity: 0;
    }
  }

  &-person-2 {
    position: absolute;
    bottom: 38%;
    left: -38.8%;
    z-index: 1;
    @include calculateItemSizeVH(63, 111);
    animation: game-1-person-3 7s 3s linear infinite;
  }

  &-person-1 {
    position: absolute;
    bottom: 38%;
    left: -38.8%;
    z-index: 1;
    @include calculateItemSizeVH(63, 111);
    animation: game-1-person-3 7s 5s linear infinite;
  }

  &-person-5 {
    position: absolute;
    bottom: 32.4%;
    right: 2.6%;
    z-index: 1;
    @include calculateItemSizeVH(800, 263);
  }

  &-target-1 {
    position: absolute;
    bottom: 19%;
    right: 42.6%;
    z-index: 1;
    @include calculateItemSizeVH(75, 87);
    pointer-events: auto;
  }

  &-target-2 {
    position: absolute;
    top: 37.5%;
    right: 54.15%;
    z-index: 1;
    @include calculateItemSizeVH(18, 23);
    pointer-events: auto;
  }

  &-target-3 {
    position: absolute;
    bottom: 36.5%;
    right: 57.5%;
    z-index: 1;
    @include calculateItemSizeVH(44, 46);
    pointer-events: auto;
  }

  &-target-4 {
    position: absolute;
    bottom: 61.5%;
    right: 89.5%;
    z-index: 1;
    @include calculateItemSizeVH(59, 59);
    pointer-events: auto;
  }

  &-target-5 {
    position: absolute;
    bottom: 32.5%;
    right: 9.2%;
    z-index: 1;
    @include calculateItemSizeVH(67, 53);
    pointer-events: auto;
  }

  &-game-1-main-group-front {
    position: absolute;
    bottom: 13.8%;
    right: -21.5%;
    z-index: 1;
    @include calculateItemSizeVH(1622, 463);
  }
}

@media (max-aspect-ratio: 7/4) {
  .game-1 {
    &-main-group {
      bottom: 6%;
      @include calculateItemSizeVW(1920, 1080);
    }

    &-main-group-back {
      @include calculateItemSizeVW(5700, 2345);
    }

    &-car {
      @include calculateItemSizeVW(225, 137);
    }

    &-person-6 {
      @include calculateItemSizeVW(101, 89);
    }

    &-trees {
      @include calculateItemSizeVW(1414, 378);
    }

    &-person-4 {
      @include calculateItemSizeVW(232, 277);
    }

    &-person-3 {
      @include calculateItemSizeVW(63, 111);
    }
  
    &-person-2 {
      @include calculateItemSizeVW(63, 111);
    }

    &-person-1 {
      @include calculateItemSizeVW(63, 111);
    }

    &-person-5 {
      @include calculateItemSizeVW(800, 263);
    } 
  
    &-target-1 {
      @include calculateItemSizeVW(75, 87);
    }
  
    &-target-2 {
      @include calculateItemSizeVW(18, 23);
    }
  
    &-target-3 {
      @include calculateItemSizeVW(44, 46);
    }

    &-target-4 {
      @include calculateItemSizeVW(59, 59);
    }

    &-target-5 {
      @include calculateItemSizeVW(67, 53);
    }
  
    &-game-1-main-group-front {
      @include calculateItemSizeVW(1622, 463);
    }
  
  }
}
