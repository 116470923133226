:root {
  --game-menu-bg-color: #2a396b;

  --game-menu-footer-section-right-bg-color: #1e2a4f;
  --game-menu-footnote-color: #6c7fbc;
}

.game-menu {
  position: relative;
  display: none;
  width: 100%;
  min-height: inherit;
  background-color: var(--game-menu-bg-color);
  overflow: hidden;

  &-container {
    justify-content: center;
  }

  &-inner {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    @include calculateItemSizeVH(1920, 1080);
    width: 100%;
    padding: 12vh 8.5vh;
  }

  &-bg-back {
    position: absolute;
    bottom: -12.74%;
    left: -15.5%;
    z-index: 1;
    @include calculateItemSizeVH(2331, 1591);
    max-width: initial;
  }

  &-bg-left {
    position: absolute;
    bottom: -36.74%;
    left: -125.5%;
    z-index: 1;
    @include calculateItemSizeVH(2690, 1416);
    max-width: initial;
    animation: left-hand-move 4s ease-in-out infinite alternate;
  }

  @keyframes left-hand-move {
    0% {
      transform: translate(0);
    }

    100% {
      transform: translate(-10%, 10%);
    }
  }

  &-bg-right {
    position: absolute;
    top: -111.74%;
    right: -66.5%;
    z-index: 1;
    @include calculateItemSizeVH(1480, 1666);
    max-width: initial;
    animation: right-hand-move 4s ease-in-out infinite alternate;
  }

  @keyframes right-hand-move {
    0% {
      transform: translate(0);
    }

    100% {
      transform: translate(10%, -10%);
    }
  }

  &-header {
    z-index: 1;
    @include fontProperty(4.65vh, 150%, 700);
  }

  &-cards {
    z-index: 1;
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  &-card {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    @include calculateItemSizeVH(378, 484);
    padding: 0;
    border: none;
    @include resetOutline;
    border-radius: 3vh;
    background-color: #fff;
    overflow: hidden;
    pointer-events: auto;
    cursor: pointer;
    transition: transform 0.3s ease-in-out;

    &:hover,
    &:focus {
      transform: translateY(-4%);
    }

    &:hover .game-menu-card-hand,
    &:focus .game-menu-card-hand {
      transform: rotate(2deg);
    }
  }

  &-card-header {
    position: absolute;
    top: 13%;
    left: 10%;
    z-index: 1;
  }

  &-card-hand {
    position: absolute;
    z-index: 1;
    transform-origin: left bottom;
    transition: transform 0.3s ease-in-out;
  }

  &-card-gradient {
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    @include calculateItemSizeVH(298, 96);
    margin-bottom: 4.6vh;
    border-radius: 5vh;
    @include fontProperty(2.8vh, 120%, 700);
    background: linear-gradient(90deg, #009fdf 0%, #62bd48 100%);
    background-size: 150% 150%;
    animation: gradient 3s linear infinite alternate;
    transition: transform 0.3s ease-in-out;
  }

  &-card-1-header {
    @include calculateItemSizeVH(305, 90);
  }

  &-card-1-hand {
    top: 23%;
    left: -33%;
    @include calculateItemSizeVH(506, 506);
  }

  &-card-2-header {
    @include calculateItemSizeVH(192, 79);
  }

  &-card-2-hand {
    top: 26%;
    left: 5%;
    @include calculateItemSizeVH(359, 417);
  }

  &-card-3-header {
    @include calculateItemSizeVH(209, 79);
  }

  &-card-3-hand {
    top: 24%;
    left: 8%;
    @include calculateItemSizeVH(496, 517);
  }

  &-card-4-header {
    @include calculateItemSizeVH(237, 92);
  }

  &-card-4-hand {
    top: 15%;
    left: -20%;
    @include calculateItemSizeVH(458, 498);
  }

  &-footer {
    z-index: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    min-height: 13vh;
  }


  &-footer-section-left {
    flex-basis: 23%;
    display: flex;
    justify-content: stretch;
  }

  &-btn-generator {
    position: relative;
    display: flex;
    justify-content: start;
    align-items: center;
    width: 100%;
    padding: 0;
    border: none;
    @include resetOutline;
    @include fontProperty(3.05vh, 100%, 400, #fff, left);
    pointer-events: auto;
    cursor: pointer;
    background-color: transparent;

    &:focus .game-menu-btn-generator-ico,
    &:hover .game-menu-btn-generator-ico {
      transform: translateX(15%);
    }

    &:hover .game-menu-btn-generator-text::after {
      transform: scaleX(0);
    }
  }

  &-btn-generator-text {
    position: relative;
    margin-right: 2vh;

    &::after {
      content: "";
      position: absolute;
      bottom: -30%;
      left: 0;
      width: 100%;
      height: 6%;
      background-color: #fff;
      transform-origin: right;
      transition: transform 0.3s ease-in-out;
    }
  }

  &-btn-generator-ico {
    @include calculateItemSizeVH(57, 46);
    transition: transform 0.3s ease-in-out;
  }

  &-footnote-left {
    flex-basis: 80%;
    padding-right: 5vh;
  }

  &-footnote-right {
    a {
      color: inherit;
      text-decoration: inherit;
    }

    address {
      font-style: inherit;
    }
  }

  &-footnote-header {
    font-size: 1.2em;
    margin-bottom: 1vh;
  }

  &-footnote-transcript {
    position: absolute;
    bottom: -15%;
    right: 5%;
  }
}

.game-menu.active {
  display: block;
}

@media (max-aspect-ratio: 7/4) {
  .game-menu {
    &-inner {
      @include calculateItemSizeVW(1920, 1080);
      width: 100%;
      padding: 8vw 5.5vw;
    }

    &-bg-back {
      @include calculateItemSizeVW(2331, 1591);
    }

    &-bg-left {
      @include calculateItemSizeVW(2690, 1416);
    }

    &-bg-right {
      @include calculateItemSizeVW(1480, 1666);
    }

    &-header {
      font-size: 2.5vw;
    }

    &-card {
      @include calculateItemSizeVW(378, 484);
    }

    &-card-gradient {
      @include calculateItemSizeVW(298, 96);
      margin-bottom: 2vw;
      font-size: 1.7vw;
    }

    &-card-1-header {
      @include calculateItemSizeVW(305, 90);
    }

    &-card-1-hand {
      @include calculateItemSizeVW(506, 506);
    }

    &-card-2-header {
      @include calculateItemSizeVW(192, 79);
    }

    &-card-2-hand {
      @include calculateItemSizeVW(359, 417);
    }

    &-card-3-header {
      @include calculateItemSizeVW(209, 79);
    }

    &-card-3-hand {
      @include calculateItemSizeVW(496, 517);
    }

    &-card-4-header {
      @include calculateItemSizeVW(237, 92);
    }

    &-card-4-hand {
      @include calculateItemSizeVW(458, 498);
    }

    &-footer {
      min-height: 7vw;
    }

    &-btn-generator {
      font-size: 1.6vw;
    }

    &-btn-generator-text {
      margin-right: 1.3vw;
    }

    &-btn-generator-ico {
      @include calculateItemSizeVW(57, 46);
    }

    &-footnote-left {
      padding-right: 2vw;
    }
  }
}
