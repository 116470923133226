.game-postcard {
  position: relative;
  display: none;
  width: 100%;
  min-height: inherit;
  background-color: var(--game-menu-bg-color);
  overflow: hidden;

  &-container {
    justify-content: center;
  }

  &-inner {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    @include calculateItemSizeVH(1920, 1080);
    width: 100%;
    padding: 4vh 8.5vh;
  }

  &-bg-back {
    position: absolute;
    bottom: -14.74%;
    left: -17.5%;
    z-index: 1;
    @include calculateItemSizeVH(2453, 1607);
    max-width: initial;
  }

  &-header {
    z-index: 1;
    @include fontProperty(4.65vh, 150%, 700);
  }

  &-cards {
    z-index: 1;
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  &-card {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    @include calculateItemSizeVH(380, 645);
    padding: 0;
    border: none;
    @include resetOutline;
    background-color: transparent;
    overflow: hidden;
    pointer-events: auto;
    text-decoration: none;
    cursor: pointer;
    transition: transform 0.3s ease-in-out;

    &:focus {
      transform: translateY(-2%);
    }

    &:hover {
      transform: translateY(-3%);
    }

    &:hover .game-menu-card-hand,
    &:focus .game-menu-card-hand {
      transform: rotate(2deg);
    }
  }

  &-card-preview {
    position: absolute;
    top: 0%;
    left: 0%;
    z-index: 1;
    @include calculateItemSizeVH(380, 519);
  }

  &-card-gradient {
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    @include calculateItemSizeVH(350, 96);
    border-radius: 5vh;
    @include fontProperty(2.8vh, 120%, 700);
    background: linear-gradient(90deg, #009fdf 0%, #62bd48 100%);
    background-size: 150% 150%;
    animation: gradient 3s linear infinite alternate;
    transition: transform 0.3s ease-in-out;
  }

  &-footnote {
    z-index: 1;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    width: 100%;
    min-height: 7vh;
    pointer-events: auto;
    @include fontProperty(1.42vh, 120%, 400, var(--game-menu-footnote-color), left);

    a {
      color: inherit;
      text-decoration: inherit;
    }

    address {
      font-style: inherit;
    }
  }

  &-footnote-left {
    flex-basis: 80%;
    padding-right: 5vh;
  }

  &-footnote-right {

  }

  &-footnote-header {
    font-size: 1.2em;
    margin-bottom: 1vh;
  }
}

.game-postcard.active {
  display: block;
}

@media (max-aspect-ratio: 7/4) {
  .game-postcard {
    &-inner {
      @include calculateItemSizeVW(1920, 1080);
      width: 100%;
      padding: 4vh 5.5vw;
    }

    &-bg-back {
      @include calculateItemSizeVW(2453, 1607);
    }

    &-logo {
      @include calculateItemSizeVW(186, 64);
    }

    &-header {
      font-size: 2.5vw;
    }

    &-card {
      @include calculateItemSizeVW(380, 645);
    }

    &-card-preview {
      @include calculateItemSizeVW(380, 519);
    }

    &-card-gradient {
      @include calculateItemSizeVW(350, 96);
      font-size: 1.7vw;
    }

    &-footnote {
      padding-top: 2vw;
      min-height: 3vw;
      font-size: 0.8vw;
    }

    &-footnote-left {
      padding-right: 2vw;
    }
  }
}
