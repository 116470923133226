:root {
  --page-start-bg-color: #2a396b;

  --page-start-content-bottom-bg-color: #e6f5fb;
  --page-start-footnote-color: #2a396b;
}

.page-start {
  justify-content: flex-start;
  align-items: center;
  padding: 2vh 7vh;
  background-color: var(--page-start-bg-color);

  &-content {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @include calculatePopupSizeVH(890, 530);
    padding: 2vh;
    border-radius: 4vh;
    background-color: #fff;

    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      right: 11%;
      z-index: -1;
      @include calculatePopupSizeVH(120, 120);
      background-color: inherit;
      transform-origin: left top;
      transform: rotate(45deg);
    }
  }

  &-content-top {
    padding: 2.3vh 4.3vh;
  }

  &-logo {
    margin-bottom: 3.3vh;
  }

  &-descr {
    margin-bottom: 1.7vh;
    @include fontProperty(3.12vh, 128%, 400, #000, left);
  }

  &-descr-strong {
    margin-bottom: 4vh;
    font-weight: 700;
  }

  &-btn {
    position: relative;
    @include calculatePopupSizeVH(450, 96);
    padding: 0;
    border: none;
    border-radius: 5vh;
    @include resetOutline;
    @include fontProperty(2.8vh, 120%, 700);
    background: linear-gradient(90deg, #009fdf 0%, #62bd48 100%);
    background-size: 150% 150%;
    animation: gradient 3s linear infinite alternate;
    transition: transform 0.3s ease-in-out;
    cursor: pointer;

    &:hover {
      transform: scale(1.07);
    }
  }
}

@media (min-aspect-ratio: 7/3) {
  .page-start {
    justify-content: center;
  }
}

@media (max-aspect-ratio: 7/5) {
  .page-start {
    padding: 2vh;
  }
}

@media (orientation: portrait) {
  .page-start {
    align-items: flex-start;
    padding: 83.2vw 6.5vw 2vw;

    &-content {
      width: 100%;
      min-height: initial;
      padding: 4vw;

      &::after {
        top: 0;
        left: 11%;
        @include calculateItemSizeVW(35, 35, 500);
        transform-origin: left top;
        transform: rotate(-45deg);
      }
    }

    &-content-top {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0 2.3vw 5vw;
    }

    &-logo {
      @include calculateItemSizeVW(125, 43, 500);
      margin-bottom: 3.3vw;
    }

    &-descr {
      margin-bottom: 5vw;
      @include fontProperty(3.8vw, 128%, 400, #000);
    }

    &-descr-strong {
      font-weight: 700;
    }

    &-btn {
      @include calculateItemSizeVW(368, 78, 500);
      @include fontProperty(4.8vw, 120%, 700);
    }
  }
}
