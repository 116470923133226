:root {
  --game-3-bg-color: #ccd8f9;
  --game-3-floor-bg-color: #a4b1e0;
  --game-3-floor-stripe-color: #8fa8e7;
}

.game-3 {
  background: linear-gradient(
    to bottom,
    var(--game-3-bg-color) 32.5%,
    var(--game-3-floor-stripe-color) 32.5% 42.9%,
    var(--game-3-bg-color) 42.9% 40%
  );

  // stripes
  &::before {
    content: "";
    position: absolute;
    top: 34.2%;
    left: 0;
    width: 100%;
    height: 0.15%;
    background: linear-gradient(to right, transparent 6.5%, #fff 6.5% 35.2%, transparent 35.2% 96%, #fff 96%);
  }

  &::after {
    content: "";
    position: absolute;
    top: 35.2%;
    left: 0;
    width: 100%;
    height: 0.15%;
    background: linear-gradient(
      to right,
      transparent 5.5%,
      #fff 5.5% 9.2%,
      transparent 9.2% 35.8%,
      #fff 35.8% 39%,
      transparent 39%
    );
  }

  &-lamp {
    position: absolute;
    top: 0;
    z-index: 1;
    @include calculateItemSizeVH(450, 50);
  }

  &-lamp-left {
    left: 17.4%;
  }

  &-lamp-right {
    right: 15.4%;
  }

  &-floor {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 46.3%;
    background-color: var(--game-3-floor-bg-color);
  }

  // left side
  &-left-door-group {
    position: absolute;
    bottom: 97%;
    left: -5%;
    z-index: 1;
    @include calculateItemSizeVH(952, 463);
    pointer-events: none;
  }

  &-left-door-group-back {
    width: inherit;
    height: inherit;
  }

  &-target-1 {
    position: absolute;
    top: 2.5%;
    right: 21.3%;
    z-index: 1;
    @include calculateItemSizeVH(80, 124);
    pointer-events: auto;
  }

  &-target-3 {
    position: absolute;
    bottom: 24.5%;
    left: 46%;
    z-index: 1;
    @include calculateItemSizeVH(75, 55);
    pointer-events: auto;
  }

  &-left-door-group-front {
    position: absolute;
    bottom: 1.6%;
    right: 35.4%;
    z-index: 1;
    @include calculateItemSizeVH(130, 270);
  }

  &-doctor-back {
    position: absolute;
    bottom: -57%;
    right: -64%;
    z-index: 1;
    @include calculateItemSizeVH(1307, 590);
  }

  &-target-4 {
    position: absolute;
    bottom: 19.8%;
    right: -4.6%;
    z-index: 1;
    @include calculateItemSizeVH(79, 119);
    pointer-events: auto;
  }

  &-doctor-front {
    position: absolute;
    bottom: 14.8%;
    right: -2.9%;
    z-index: 1;
    @include calculateItemSizeVH(94, 121);
  }

  &-grandpa {
    position: absolute;
    bottom: 0%;
    left: 2.4%;
    z-index: 1;
    @include calculateItemSizeVH(331, 522);
  }

  &-grandpa-back {
    position: absolute;
    bottom: 54.6%;
    left: 51.1%;
    z-index: 1;
    @include calculateItemSizeVH(133, 121);
  }

  &-notebook-lines {
    position: absolute;
    bottom: 54.6%;
    left: 54.1%;
    z-index: 1;
    @include calculateItemSizeVH(60, 90);
    transform-origin: right bottom;
    transform: rotate(26deg);
  }

  &-notebook-line {
    position: absolute;
    top: 0%;
    left: 0%;
    z-index: 1;
    width: 100%;
    height: 2%;
    border-radius: 1px;
    background-color: #25357b;
    transform-origin: left;
    transform: rotate(-15deg);
    opacity: 0;
  }

  &-notebook-line:nth-child(1) {
    animation: show-lines-1 10s linear infinite;
    @keyframes show-lines-1 {
      0% {
        opacity: 0;
      }

      12.5% {
        opacity: 0;
      }

      17.5% {
        opacity: 1;
      }

      100% {
        opacity: 1;
      }
    }
  }

  &-notebook-line:nth-child(2) {
    top: 10%;
    animation: show-lines-2 10s linear infinite;
    @keyframes show-lines-2 {
      0% {
        opacity: 0;
      }

      37.5% {
        opacity: 0;
      }

      39.5% {
        opacity: 1;
      }

      100% {
        opacity: 1;
      }
    }
  }

  &-notebook-line:nth-child(3) {
    top: 20%;
    animation: show-lines-3 10s linear infinite;
    @keyframes show-lines-3 {
      0% {
        opacity: 0;
      }

      62.5% {
        opacity: 0;
      }

      64.5% {
        opacity: 1;
      }

      100% {
        opacity: 1;
      }
    }
  }

  &-notebook-line:nth-child(4) {
    top: 30%;
    animation: show-lines-4 10s linear infinite;
    @keyframes show-lines-4 {
      0% {
        opacity: 0;
      }

      87.5% {
        opacity: 0;
      }

      89.5% {
        opacity: 1;
      }

      100% {
        opacity: 1;
      }
    }
  }

  &-grandpa-hand {
    position: absolute;
    bottom: 61.2%;
    left: 35.1%;
    z-index: 1;
    @include calculateItemSizeVH(112, 120);
    animation: grandpa-hand-move 10s linear infinite;

    @keyframes grandpa-hand-move {
      0% {
        transform: translate(0);
      }

      12.5% {
        transform: translate(49%, 9%);
      }

      25% {
        transform: translate(-4%, 7%);
      }

      37.5% {
        transform: translate(46%, 16%);
      }

      50% {
        transform: translate(-6%, 14%);
      }

      62.5% {
        transform: translate(43%, 23%);
      }

      75% {
        transform: translate(-10%, 20%);
      }

      87.5% {
        transform: translate(39%, 30%);
      }

      100% {
        transform: translate(0);
      }
    }
  }

  &-grandpa-front {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: inherit;
    height: inherit;
  }

  &-plant-left {
    position: absolute;
    bottom: 0%;
    left: -2%;
    z-index: 1;
    @include calculateItemSizeVH(121, 206);
  }

  &-plant-left-top {
    position: absolute;
    bottom: 87%;
    left: -70%;
    z-index: 1;
    @include calculateItemSizeVH(270, 252);
    transform-origin: bottom;
    animation: plant-swing 4s linear infinite;

    @keyframes plant-swing {
      0% {
        transform: rotate(-2deg);
      }

      50% {
        transform: rotate(2deg);
      }

      100% {
        transform: rotate(-2deg);
      }
    }
  }

  &-plant-left-bottom {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    width: inherit;
    height: inherit;
  }

  // right side
  &-right-group {
    position: absolute;
    bottom: 81%;
    right: 7.7%;
    z-index: 1;
    @include calculateItemSizeVH(852, 551);
    pointer-events: none;
  }

  &-right-group-back {
    width: inherit;
    height: inherit;
  }

  &-target-5 {
    position: absolute;
    top: 44.7%;
    left: 6.7%;
    z-index: 1;
    @include calculateItemSizeVH(77, 52);
    pointer-events: auto;
  }

  &-right-group-front {
    position: absolute;
    top: 38%;
    left: 8.7%;
    z-index: 1;
    @include calculateItemSizeVH(141, 89);
    pointer-events: none;
  }

  &-right-phone-group {
    position: absolute;
    bottom: 0%;
    right: -3.6%;
    z-index: 1;
    @include calculateItemSizeVH(467, 740);
  }

  &-right-phone-group-back {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    width: inherit;
    height: inherit;
  }

  &-target-2 {
    position: absolute;
    top: 25.4%;
    right: 61.9%;
    z-index: 1;
    @include calculateItemSizeVH(102, 121);
    pointer-events: auto;
  }

  &-right-phone-group-front {
    position: absolute;
    bottom: 59.8%;
    left: 20.4%;
    z-index: 1;
    @include calculateItemSizeVH(27, 45);
  }

  &-plant-right-top {
    position: absolute;
    bottom: 38.4%;
    right: 7%;
    z-index: 1;
    @include calculateItemSizeVH(172, 253);
    transform-origin: bottom right;
    animation: plant-swing 6s 1s linear infinite;
  }

  &-plant-right-bottom {
    position: absolute;
    bottom: 35.4%;
    right: 3%;
    z-index: 1;
    @include calculateItemSizeVH(86, 70);
  }
}

@media (max-aspect-ratio: 7/4) {
  .game-3 {
    background: linear-gradient(
      to bottom,
      var(--game-3-bg-color) 39.5%,
      var(--game-3-floor-stripe-color) 39.5% 47.9%,
      var(--game-3-bg-color) 47.9% 40%
    );

    // stripes
    &::before {
      top: 41%;
    }

    &::after {
      top: 42%;
    }

    &-lamp {
      @include calculateItemSizeVW(450, 50);
    }

    &-left-door-group {
      @include calculateItemSizeVW(952, 463);
    }

    &-left-group {
      @include calculateItemSizeVW(1465, 730);
    }

    &-target-1 {
      @include calculateItemSizeVW(80, 124);
    }

    &-target-3 {
      @include calculateItemSizeVH(75, 55);
    }

    &-left-door-group-front {
      @include calculateItemSizeVW(130, 270);
    }

    &-doctor-back {
      @include calculateItemSizeVW(1307, 590);
    }

    &-target-4 {
      @include calculateItemSizeVW(79, 119);
    }

    &-doctor-front {
      @include calculateItemSizeVW(94, 121);
    }

    &-grandpa {
      @include calculateItemSizeVW(331, 522);
    }

    &-grandpa-back {
      @include calculateItemSizeVW(133, 121);
    }

    &-notebook-lines {
      @include calculateItemSizeVW(60, 90);
    }

    &-grandpa-hand {
      @include calculateItemSizeVW(112, 120);
    }

    &-plant-left {
      @include calculateItemSizeVW(121, 206);
    }

    &-plant-left-top {
      @include calculateItemSizeVW(270, 252);
    }

    &-right-group {
      @include calculateItemSizeVW(852, 551);
    }

    &-target-5 {
      @include calculateItemSizeVW(77, 52);
    }

    &-right-group-front {
      @include calculateItemSizeVW(141, 89);
    }

    &-right-phone-group {
      @include calculateItemSizeVW(467, 740);
    } 

    &-target-2 {
      @include calculateItemSizeVW(102, 121);
    }
  
    &-plant-right-top {
      @include calculateItemSizeVW(172, 253);
    }

    &-plant-right-bottom {
      @include calculateItemSizeVW(86, 70);
    }
  
    
  }
}
