.modal-rotate {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: none;
  min-height: inherit;
  z-index: 5000;
  background-color: var(--game-menu-bg-color);
}

@media (orientation: portrait) {
  .active.page-game .modal-rotate {
    display: block;
  }
}

.modal-rotate-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 2vh 0;
  z-index: 1;
}

.modal-rotate-subheader {
  @include fontProperty(4vh, 100%, 700);
}

.mobile-pic {
  display: flex;
  align-items: center;
  justify-content: center;
  @include calculateItemSizeVW(396, 718, 500);
  animation: 2.2s ease-in-out 0s infinite swing-modal-pic;
}

.mobile-pic img {
  height: 100%;
  width: 100%;
}

@keyframes swing-modal-pic {
  0% {
    transform: rotate(3deg);
  }
  50% {
    transform: rotate(-3deg);
  }
  100% {
    transform: rotate(3deg);
  }
}

@media (min-aspect-ratio: 5/8) {
  .mobile-pic {
    @include calculateItemSizeVH(396, 718, 800);
  }
}

@media (max-aspect-ratio: 5/8) {
  .modal-rotate-subheader {
    font-size: 7vw;
  }
}
