:root {
  --game-2-bg-color: #d8e7ff;
  --game-2-bg-stripe-color: #c9defd;
  --game-2-clock-border-color: #94c2ff;
  --game-2-clock-point-color: #aad0e6;

  --game-2-floor-bg-color: #b4bcd4;
  --game-2-floor-border-color: #969db3;
}

.game-2 {
  background-color: var(--game-2-bg-color);

  &-clock {
    position: absolute;
    top: 9.7%;
    right: 16%;
    z-index: 1;
    @include calculateItemSizeVH(124, 124);
    border: 0.5vh solid var(--game-2-clock-border-color);
    border-radius: 50%;
    background-color: #fff;

    &::before {
      content: "";
      position: absolute;
      left: 48.5%;
      top: 48.5%;
      width: 40%;
      height: 3%;
      background-color: var(--game-2-clock-border-color);
      border-radius: 100px;
      transform-origin: left;
      animation: clock-arrow-rotate 30s linear infinite;

      @keyframes clock-arrow-rotate {
        0% {
          transform: rotate(0);
        }

        100% {
          transform: rotate(360deg);
        }
      }
    }

    &::after {
      content: "";
      position: absolute;
      left: 48.5%;
      top: 48.5%;
      width: 30%;
      height: 3%;
      background-color: var(--game-2-clock-border-color);
      border-radius: 100px;
      transform-origin: left;
      animation: clock-arrow-rotate 360s linear infinite;

      @keyframes clock-arrow-rotate {
        0% {
          transform: rotate(0);
        }

        100% {
          transform: rotate(360deg);
        }
      }
    }

    &-point {
      position: absolute;
      top: 46%;
      left: 46%;
      z-index: 1;
      width: 8%;
      height: 8%;
      border-radius: 50%;
      background-color: var(--game-2-clock-point-color);
    }
  }

  &-lamp {
    position: absolute;
    top: 0;
    z-index: 1;
    @include calculateItemSizeVH(450, 50);
  }

  &-lamp-left {
    left: 15%;
  }

  &-lamp-right {
    right: 16%;
  }

  &-floor {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 31%;
    background-color: var(--game-2-floor-bg-color);

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 7%;
      background-color: var(--game-2-floor-border-color);
    }
  }

  // stripes
  &-container-main::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      to right,
      transparent 34%,
      var(--game-2-bg-stripe-color) 34% 34.3%,
      transparent 34.3% 40%,
      var(--game-2-bg-stripe-color) 40% 40.3%,
      transparent 40.3% 58.7%,
      var(--game-2-bg-stripe-color) 58.7% 59%,
      transparent 59% 65%,
      var(--game-2-bg-stripe-color) 65% 65.3%,
      transparent 65.3% 100%
    );
    z-index: 0;
  }

  &-door {
    position: absolute;
    bottom: 92%;
    left: 42%;
    z-index: 1;
    @include calculateItemSizeVH(296, 596);
  }

  &-floor-stripes {
    position: absolute;
    top: 13%;
    right: 1%;
    @include calculateItemSizeVH(1306, 59);
  }

  // left side
  &-left-group {
    position: absolute;
    bottom: 91%;
    left: 2.6%;
    z-index: 1;
    @include calculateItemSizeVH(713, 625);
    pointer-events: none;
  }

  &-left-group-back {
    width: inherit;
    height: inherit;
  }

  &-target-1 {
    position: absolute;
    bottom: 22%;
    left: -1.4%;
    z-index: 1;
    @include calculateItemSizeVH(96, 109);
    pointer-events: auto;
  }

  &-target-2 {
    position: absolute;
    top: 14%;
    right: 4.6%;
    z-index: 1;
    @include calculateItemSizeVH(55, 107);
    pointer-events: auto;
  }

  &-target-2-body {
    position: absolute;
    left: 0;
    top: 0;
    width: inherit;
    height: inherit;
  }

  &-target-2-head {
    position: absolute;
    top: 0%;
    right: 30%;
    @include calculateItemSizeVH(24, 27);
    transform-origin: bottom center;
    animation: head-swing 2s linear infinite;
  }

  @keyframes head-swing {
    0% {
      transform: rotate(-15deg);
    }

    50% {
      transform: rotate(15deg);
    }

    100% {
      transform: rotate(-15deg);
    }
  }

  &-left-group-front {
    position: absolute;
    bottom: -29.5%;
    left: -5.6%;
    z-index: 1;
    @include calculateItemSizeVH(818, 656);
    pointer-events: none;
  }

  // right side
  &-right-group {
    position: absolute;
    bottom: 74%;
    right: 0.4%;
    z-index: 1;
    @include calculateItemSizeVH(762, 676);
    pointer-events: none;
  }

  &-right-group-back {
    position: absolute;
    bottom: 1%;
    right: 1%;
    @include calculateItemSizeVH(753, 592);
    pointer-events: none;
  }

  &-target-4 {
    position: absolute;
    top: 14.4%;
    left: 24.1%;
    z-index: 1;
    @include calculateItemSizeVH(153, 110);
    pointer-events: auto;
  }

  &-target-4-body {
    position: absolute;
    left: 0;
    top: 0;
    width: inherit;
    height: inherit;
  }

  &-target-4-problem {
    position: absolute;
    bottom: 16%;
    right: 21%;
    @include calculateItemSizeVH(28, 28);
    transform-origin: bottom center;
    animation: problem-blink 0.7s linear infinite alternate;
  }

  @keyframes problem-blink {
    0% {
      transform: scale(1);
      opacity: 0.8;
    }

    100% {
      transform: scale(1.05);
      opacity: 1;
    }
  }

  &-doctor {
    position: absolute;
    top: 5.6%;
    left: 5.5%;
    z-index: 1;
    @include calculateItemSizeVH(269, 609);
  }

  &-doctor-back {
    position: absolute;
    left: 0;
    top: 0;
    width: inherit;
    height: inherit;
    pointer-events: none;
  }

  &-target-3 {
    position: absolute;
    top: 29.9%;
    left: 19.8%;
    @include calculateItemSizeVH(100, 113);
    pointer-events: auto;
  }

  &-doctor-front {
    position: absolute;
    top: 19.9%;
    left: 0%;
    @include calculateItemSizeVH(186, 157);
    transform-origin: top left;
    animation: doctor-hand-swing 2s linear infinite alternate;
    pointer-events: none;
  }

  @keyframes doctor-hand-swing {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(5deg);
    }
  }

  &-right-group-front {
    position: absolute;
    bottom: -4%;
    right: 9%;
    z-index: 1;
    @include calculateItemSizeVH(695, 440);
    pointer-events: none;
  }

  &-car {
    position: absolute;
    bottom: -2%;
    left: 25%;
    z-index: 1;
    @include calculateItemSizeVH(107, 45);
    animation: car-drive 4s linear infinite alternate;
  }

  @keyframes car-drive {
    0% {
      transform: translate(0);
    }

    100% {
      transform: translate(120%);
    }
  }

  &-table {
    position: absolute;
    bottom: -5.4%;
    right: 0.5%;
    z-index: 1;
    @include calculateItemSizeVH(163, 224);
  }

  &-table-back {
    position: absolute;
    left: 0;
    top: 0;
    width: inherit;
    height: inherit;
  }

  &-target-5 {
    position: absolute;
    top: -23.4%;
    right: 2.8%;
    @include calculateItemSizeVH(60, 54);
    pointer-events: auto;
  }

  &-table-front {
    position: absolute;
    top: -35.1%;
    right: 19.8%;
    @include calculateItemSizeVH(110, 81);
  }
}

@media (max-aspect-ratio: 7/4) {
  .game-2 {
    &-clock {
      @include calculateItemSizeVW(124, 124);
    }

    &-lamp {
      @include calculateItemSizeVW(450, 50);
    }

    &-door {
      @include calculateItemSizeVW(296, 596);
    }

    &-floor-stripes {
      @include calculateItemSizeVW(1306, 59);
    }

    &-left-group {
      @include calculateItemSizeVW(713, 625);
    }

    &-target-1 {
      @include calculateItemSizeVW(96, 109);
    }

    &-target-2 {
      @include calculateItemSizeVW(55, 107);
    }

    &-target-2-head {
      @include calculateItemSizeVW(24, 27);
    }

    &-left-group-front {
      @include calculateItemSizeVW(818, 656);
    }

    &-right-group {
      @include calculateItemSizeVW(762, 676);
    }

    &-right-group-back {
      @include calculateItemSizeVW(753, 592);
    }

    &-target-4 {
      @include calculateItemSizeVW(153, 110);
    }

    &-target-4-problem {
      @include calculateItemSizeVW(28, 28);
    }

    &-doctor {
      @include calculateItemSizeVW(269, 609);
    }

    &-target-3 {
      @include calculateItemSizeVW(100, 113);
    }

    &-doctor-front {
      @include calculateItemSizeVW(186, 157);
    }

    &-right-group-front {
      @include calculateItemSizeVW(695, 440);
    }

    &-car {
      @include calculateItemSizeVW(107, 45);
    }

    &-table {
      @include calculateItemSizeVW(163, 224);
    }

    &-target-5 {
      @include calculateItemSizeVW(60, 54);
    }

    &-table-front {
      @include calculateItemSizeVW(110, 81);
    }
  }
}
