:root {
  --game-4-bg-color: #d8e7ff;
  --game-4-floor-bg-color: #b4bcd4;

}

.game-4 {
  background-color: var(--game-4-bg-color);

  &-lamp {
    position: absolute;
    top: 0;
    z-index: 1;
    @include calculateItemSizeVH(450, 50);
  }

  &-lamp-left {
    left: 28.5%;
  }

  &-lamp-right {
    right: 7.8%;
  }

  &-floor {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 37.8%;
    background-color: var(--game-4-floor-bg-color);
  }

  &-door {
    position: absolute;
    bottom: 100%;
    left: 1.2%;
    z-index: 1;
    @include calculateItemSizeVH(411, 596);
    pointer-events: none;
  }

  &-right-group {
    position: absolute;
    bottom: 60.8%;
    right: 0%;
    z-index: 1;
    @include calculateItemSizeVH(1832, 741);
    pointer-events: none;
  }

  &-right-group-back {
    position: absolute;
    left: 0;
    top: 0;
    width: inherit;
    height: inherit;
  }

  &-dino {
    position: absolute;
    top: 23.4%;
    left: 36.8%;
    z-index: 1;
    @include calculateItemSizeVH(129, 72);
    animation: dino-going 9s linear infinite alternate;
  }

  @keyframes dino-going {
    0% {
      transform: translate(0);
    }

    100% {
      transform: translate(80%);
    }
  }

  &-ray {
    position: absolute;
    top: 4.1%;
    right: 10.2%;
    z-index: 1;
    @include calculateItemSizeVH(67, 85);
    animation: ray-pulse 1s linear infinite alternate;
  }

  @keyframes ray-pulse {
    0% {
      transform: scale(0.9);
    }

    100% {
      transform: scale(1.4);
    }
  }

  &-target-1 {
    position: absolute;
    bottom: 15.5%;
    right: 36.9%;
    @include calculateItemSizeVH(90, 90);
    pointer-events: auto;
    animation: ball-rolling 5s linear infinite alternate;
  }

  @keyframes ball-rolling {
    0% {
      transform: translateX(0) rotate(0);
    }

    100% {
      transform: translateX(50%) rotate(90deg);
    }
  }

  &-target-2 {
    position: absolute;
    top: 4.5%;
    left: 25.4%;
    @include calculateItemSizeVH(55, 112);
    pointer-events: auto;
  }

  &-target-3 {
    position: absolute;
    bottom: 39.2%;
    right: 15.3%;
    @include calculateItemSizeVH(104, 101);
    pointer-events: auto;
  }

  &-target-4 {
    position: absolute;
    bottom: 39.9%;
    left: 30%;
    @include calculateItemSizeVH(146, 163);
    pointer-events: auto;
  }

  &-target-5 {
    position: absolute;
    bottom: 19.3%;
    left: 38.9%;
    @include calculateItemSizeVH(69, 60);
    pointer-events: auto;
    transform-origin: top right;
    animation: nippler-swing 2s linear infinite alternate;
  }

  @keyframes nippler-swing {
    0% {
      transform: rotate(0);
    }

    100% {
      transform: rotate(20deg);
    }
  }

  &-right-group-front {
    position: absolute;
    bottom: -4%;
    right: 4%;
    z-index: 1;
    @include calculateItemSizeVH(1513, 790);
    pointer-events: none;
  }

}

@media (max-aspect-ratio: 7/4) {
  .game-4 {
    &-lamp {
      @include calculateItemSizeVW(450, 50);
    }

    &-door {
      @include calculateItemSizeVW(411, 596);
    }

    &-right-group {
      @include calculateItemSizeVW(1832, 741);
    }
  
    &-dino {
      @include calculateItemSizeVW(129, 72);
    }
  
    &-ray {
      @include calculateItemSizeVW(67, 85);
    }
  
    &-target-1 {
      @include calculateItemSizeVW(90, 90);
    }
  
    &-target-2 {
      @include calculateItemSizeVW(55, 112);
    }
  
    &-target-3 {
      @include calculateItemSizeVW(104, 101);
    }
  
    &-target-4 {
      @include calculateItemSizeVW(146, 163);
    }
  
    &-target-5 {
      @include calculateItemSizeVW(69, 60);
    }
  
    &-right-group-front {
      @include calculateItemSizeVW(1513, 790);
    }
  
  }
}